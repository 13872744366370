<template>
<div style="width:100%">
  <buscar :session="session" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></buscar>
  <br>
  <v-form ref="form">
    <solicitante :form_solicitante="form_solicitante" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></solicitante>
    <br>
    <cliente :form_cliente="form_cliente" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></cliente>
    <br>
    <producto :form_producto="form_producto" :form_vehiculo="form_vehiculo" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></producto>
    <br>
    <qr :form_qr="form_qr" :session="session" :form_files="form_files" :form_duplicado="form_duplicado" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></qr>
    <br>
  </v-form>
  <v-row no-gutters v-if="session.area !== 17">
    <v-col cols="12" class="text-center">
      <v-btn @click="validar" block large :color="colores.black" dark>GUARDAR</v-btn>
    </v-col>
  </v-row>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  <br><br><br><br>
</div>
</template>

<script>
// import buscar from '@/components/crm/buscarComponent.vue'
// import solicitante from '@/components/crm/form_solicitanteComponent.vue'
// import cliente from '@/components/crm/form_clienteComponent.vue'
// import producto from '@/components/crm/form_productoComponent.vue'
// import qr from '@/components/crm/form_qrComponent.vue'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'

export default {
  name: 'crm_formComponent',
  components: {
    buscar: () => import('@/components/crm/buscarComponent.vue'),
    solicitante: () => import('@/components/crm/form_solicitanteComponent.vue'),
    cliente: () => import('@/components/crm/form_clienteComponent.vue'),
    producto: () => import('@/components/crm/form_productoComponent.vue'),
    qr: () => import('@/components/crm/form_qrComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  props: ['colores', 'items', 'loadingData', 'session', 'tokenInbound'],
  mixins: [recGestiones, recFunciones],
  data: () => ({
    rules: null,
    form_solicitante: { gestion_id: 0, tipo_id: 0, nombre: '', telefono: '', correo: '' },
    form_cliente: { gestion_id: 0, tipo_id: 0, documento_id: 0, identificacion: '', nombre: '', sexo_id: null, lgbtiq_id: null, especial_id: null },
    form_producto: { gestion_id: 0, producto_id: 0, productodigital_id: 0, numero: '', pais_id: 0, departamento_id: 0, municipio_id: 0 },
    form_qr: { aplicativo_id: 2, keyform: 0, id_usuario: null, codigo: 0, tipo_id: 0, instancia_id: 0, canal_id: null, admision_id: null, entecontrol_id: null, motivo_id: 0, submotivo_id: 0, quejaexpres_id: null, descripcion: '', fecha_requerimiento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), segundos: 0, subestado_id: 2, escalado_id: 1, fechacerrado: null, id_usuariocerro: 0, fechaparacerrarexterno: null, fechaparacerrarinterno: null, fechaparacerrarprorroga: null, fechaparacerrarexpres: null, diasext: 0, diasint: 0, area: null },
    form_duplicado: { gestion_id: 0, duplicado_id: 0, duplicadomotivo_id: null, reincidente_id: 0, canaoriginol_id: null, marcacion_id: null, codigo: 0, correoenvio_id: 0 },
    form_vehiculo: { gestion_id: 0, placa: '' },
    form_files: { items_archivos: [] },
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_gestion_inicializar()
      }
    }
  },
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_nuevo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    }
  }

}
</script>
