import axios from 'axios'
export default {
  methods: {
    rec_gestion_por_id (pId) {
      this.loandingQR = true
      const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
      this.registro = null
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.dataReplica = []
        this.registro = response.data
        this.form_confirmar.codigo_sfc = (this.registro.gesdato === null) ? '' : this.registro.gesdato.codigo_sfc
        this.estadoReplica(this.registro)
      }).catch(error => {
        this.dataReplica = []
        console.log('error ' + error)
      }).finally(() => {
        this.loandingQR = false
      })
    },
    rec_gestion_noreload_por_id (pId) {
      if (pId > 0) {
        const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.registro = response.data
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
        })
      }
    },
    rec_gestion_datos () {
      this.form_solicitante.nombre = this.rec_funciones_mayuscula(this.form_solicitante.nombre)
      this.form_solicitante.correo = this.rec_funciones_minusculas(this.form_solicitante.correo)

      this.form_cliente.nombre = this.rec_funciones_mayuscula(this.form_cliente.nombre)
      this.form_vehiculo.placa = this.rec_funciones_mayuscula(this.form_vehiculo.placa)

      this.form_qr.id_usuario = this.session.id
      this.form_qr.descripcion = this.rec_funciones_mayuscula(this.form_qr.descripcion)

      // DEFINIR SI ES DUPLICADO O RADICADO O ESCALADO
      this.form_qr.subestado_id = 2
      this.form_qr.escalado_id = 2
      if (this.form_duplicado.duplicado_id === 29) {
        // DUPLICADO
        this.form_qr.subestado_id = 4
        this.form_qr.escalado_id = 4
      } else if (this.form_producto.producto_id === 4 || this.form_producto.producto_id === 1) {
        // USUARIOS - ODT
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      } else if ((this.form_qr.tipo_id === 76 || this.form_qr.tipo_id === 77) && this.form_qr.motivo_id === 52) {
        // FRAUDE
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      }
    },
    rec_gestion_nuevo () {
      this.rec_gestion_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null

      const urlroute = this.$store.getters.getUrl + 'gestion'
      const data = { gestion: this.form_qr, solicitante: this.form_solicitante, cliente: this.form_cliente, producto: this.form_producto, duplicado: this.form_duplicado, vehiculo: this.form_vehiculo }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.dialogo.objPayload = response.data
        this.form_qr.keyform = this.rec_funciones_key()
        this.dialogo.detalle = true
        this.rec_gestion_inicializar()
        this.$refs.form.resetValidation()
        this.registro = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_inicializar () {
      this.form_qr.keyform = this.rec_funciones_key()
      this.dataReplica = []
      this.items.objTipoQR = null
      this.items.objSubMotivoQR = null
      this.items.objTipoQR = null
      this.items.showMarcacion = false
      this.form_solicitante.gestion_id = 0
      this.form_solicitante.tipo_id = 0
      this.form_solicitante.nombre = ''
      this.form_solicitante.telefono = ''
      this.form_solicitante.correo = ''

      this.form_cliente.gestion_id = 0
      this.form_cliente.tipo_id = 0
      this.form_cliente.documento_id = 0
      this.form_cliente.identificacion = ''
      this.form_cliente.nombre = ''
      this.form_cliente.sexo_id = null
      this.form_cliente.lgbtiq_id = null
      this.form_cliente.especial_id = null

      this.form_producto.gestion_id = 0
      this.form_producto.producto_id = 0
      this.form_producto.productodigital_id = 0
      this.form_producto.numero = ''
      this.form_producto.pais_id = 0
      this.form_producto.departamento_id = 0
      this.form_producto.municipio_id = 0
      this.form_producto.placa = ''

      this.form_qr.aplicativo_id = 2
      this.form_qr.id_usuario = null
      this.form_qr.codigo = 0
      this.form_qr.tipo_id = 0
      this.form_qr.instancia_id = 0
      this.form_qr.canal_id = null
      this.form_qr.admision_id = null
      this.form_qr.entecontrol_id = null
      this.form_qr.motivo_id = 0
      this.form_qr.submotivo_id = 0
      this.form_qr.quejaexpres_id = null
      this.form_qr.descripcion = ''
      this.form_qr.fecha_requerimiento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.form_qr.segundos = 0
      this.form_qr.subestado_id = 2
      this.form_qr.escalado_id = 1
      this.form_qr.fechacerrado = null
      this.form_qr.diasint = 0
      this.form_qr.diasext = 0
      this.form_qr.fechaparacerrarexterno = null
      this.form_qr.fechaparacerrarinterno = null
      this.form_qr.area = null

      this.form_duplicado.gestion_id = 0
      this.form_duplicado.duplicado_id = 0
      this.form_duplicado.duplicadomotivo_id = null
      this.form_duplicado.reincidente_id = 0
      this.form_duplicado.canaoriginol_id = null
      this.form_duplicado.marcacion_id = null
      this.form_duplicado.codigo = 0
      this.form_duplicado.correoenvio_id = 0

      this.form_files.items_archivos = []
    },
    rec_gestion_confirmar_datos_datos () {
      this.form_confirmar.gestion_id = this.registro.id
    },
    rec_gestion_confirmar_datos (Accion) {
      this.rec_gestion_confirmar_datos_datos()
      if (Accion === 'Crear') {
        this.dialogo.pregunta = false
        this.dialogo.esperar = true
      }
      this.form_confirmar.codigo_dcf = (this.form_confirmar.codigo_dcf === null) ? '' : this.form_confirmar.codigo_dcf
      this.form_confirmar.codigo_tutela = (this.form_confirmar.codigo_tutela === null) ? '' : this.form_confirmar.codigo_tutela
      this.form_confirmar.codigo_interno = (this.form_confirmar.codigo_interno === null) ? '' : this.form_confirmar.codigo_interno

      const urlroute = this.$store.getters.getUrl + 'gestion_confirmar'
      const data = { confirmar: this.form_confirmar }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.showEscalamiento = true
        this.esperar = false
        this.registro.gesdato = response.data
        this.updated = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        if (Accion === 'Crear') this.dialogo.esperar = false
      })
    },
    rec_gestion_confirmar_inicializar () {
      this.form_confirmar.gestion_id = 0
      this.form_confirmar.correo = ''
      this.form_confirmar.codigo_sfc = ''
      this.form_confirmar.codigo_dcf = ''
      this.form_confirmar.codigo_tutela = ''
      this.form_confirmar.codigo_interno = ''
      this.form_confirmar.proteccion_dp = false
    },
    rec_gestion_confirmar_entidad (data) {
      this.form_confirmar.gestion_id = data.gestion_id
      this.form_confirmar.correo = data.correo
      this.form_confirmar.codigo_sfc = data.codigo_sfc
      this.form_confirmar.codigo_dcf = data.codigo_dcf
      this.form_confirmar.codigo_tutela = data.codigo_tutela
      this.form_confirmar.codigo_interno = data.codigo_interno
      if (data.proteccion_dp === 1) {
        this.form_confirmar.proteccion_dp = true
      } else {
        this.form_confirmar.proteccion_dp = false
      }
    },
    rec_gestion_escalamientos_datos_datos () {
      this.form_objs.gestion_id = this.gestion_id
      this.form_objs.id_usuario = this.session.id
      this.form_objs.descripcion = this.rec_funciones_mayuscula(this.form_objs.descripcion)
    },
    rec_gestion_escalamientos_datos () {
      this.rec_gestion_escalamientos_datos_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true

      const urlroute = this.$store.getters.getUrl + 'gestion_escalamientos'
      const data = { form: this.form_objs, codigo: this.registro.codigo }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.rec_gestion_escalamientos_inicializar()
        this.$emit('update_registro', response.data)
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_escalamientos_inicializar () {
      this.form_objs.keyform = this.rec_funciones_key()
      this.rec_gestion_inicializar_otro()
      this.form_objs.gestion_id = 0
      this.form_objs.id_usuario = 0
      this.form_objs.descripcion = ''
      this.form_objs.items_escalamiento = []
    },
    rec_gestion_datos_cierre_odts () {
      this.form_odt.gestion_id = this.gestion_id
      this.form_odt.id_usuario = this.session.id
    },
    rec_gestion_cierre_odt () {
      this.rec_gestion_datos_cierre_odts()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true

      const urlroute = this.$store.getters.getUrl + 'gestion_cierre_odt'
      const data = { odt: this.form_odt }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.registro.gesodt = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_datos_cierre_final () {
      this.form_cerrado.gestion_id = this.gestion_id
      // this.form_cerrado.id_usuario = this.session.id
      this.form_consumidor.gestion_id = this.gestion_id
      this.form_tutela.gestion_id = this.gestion_id
    },
    rec_gestion_datos_cierre_prorroga () {
      this.form_cerrado.gestion_id = this.gestion_id
      // this.form_cerrado.id_usuario = this.session.id
    },
    rec_gestion_datos_cierre_replica () {
      this.form_cerrado.gestion_id = this.gestion_id
      this.form_cerrado.replica_id = this.dataReplica[0].id
    },
    rec_gestion_cierre_tipo () {
      if (this.registro.gescierre !== null || this.form_cerrado.prorroga_id === 30) {
        this.rec_gestion_cierre_final()
      } else {
        this.rec_gestion_cierre_prorroga()
      }
    },
    rec_gestion_cierre_prorroga () {
      this.rec_gestion_datos_cierre_prorroga()
      var fechaparacerrarprorroga = null
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      fechaparacerrarprorroga = this.form_cerrado.fechaparacerrarprorroga
      delete this.form_cerrado.fechaparacerrarprorroga
      const urlroute = this.$store.getters.getUrl + 'gestion_prorroga'
      const data = { cerrado: this.form_cerrado, id_usuario: this.session.id, registro: this.registro, fechaparacerrarprorroga: fechaparacerrarprorroga }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.$emit('refresh_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_cierre_final () {
      this.rec_gestion_datos_cierre_final()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true

      const urlroute = this.$store.getters.getUrl + 'gestion_final'
      const data = { id_usuario: this.session.id, consumidor: this.form_consumidor, tutela: this.form_tutela, cerrado: this.form_cerrado, analisis: this.form_analisis, registro: this.registro, duplicado: this.form_duplicado }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.$emit('refresh_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_cierre_tipo_replica () {
      if (this.form_cerrado.prorroga_id === 30 || this.dataReplica[0].prorroga_id !== null) {
        this.rec_gestion_cierre_final_replica()
      } else if (this.form_cerrado.prorroga_id === 29) {
        this.rec_gestion_cierre_prorroga_replica()
      }
    },
    rec_gestion_cierre_prorroga_replica () {
      this.rec_gestion_datos_cierre_replica()
      var fechaparacerrarprorroga = null
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      fechaparacerrarprorroga = this.form_cerrado.fechaparacerrarprorroga
      delete this.form_cerrado.fechaparacerrarprorroga
      const urlroute = this.$store.getters.getUrl + 'gestion_prorroga_replica'
      const data = { cerrado: this.form_cerrado, id_usuario: this.session.id, registro: this.registro, fechaparacerrarprorroga: fechaparacerrarprorroga }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.$emit('refresh_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_cierre_final_replica () {
      this.rec_gestion_datos_cierre_replica()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true

      const urlroute = this.$store.getters.getUrl + 'gestion_final_replica'
      const data = { id_usuario: this.session.id, cerrado: this.form_cerrado, registro: this.registro, duplicado: this.form_duplicado }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.$emit('refresh_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_cierre_otro_datos () {
      this.form_otro.gestion_id = this.gestion_id
      this.form_otro.geslog_id = 0
      this.form_otro.correoenvio_id = 0
      this.form_otro.correo = this.rec_funciones_minusculas(this.form_otro.correo)
      this.form_otro.id_usuario = this.session.id
      this.form_otro.descripcion = this.rec_funciones_mayuscula(this.form_otro.descripcion)
    },
    rec_gestion_cierre_otro () {
      this.rec_gestion_cierre_otro_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true

      const urlroute = this.$store.getters.getUrl + 'gestion_cierre_otros'
      const data = { form: this.form_otro, codigo: this.codigo }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.rec_gestion_inicializar_otro()
        this.$emit('update_registro', response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_inicializar_otro () {
      this.form_otro.keyform = this.rec_funciones_key()
      this.form_otro.gestion_id = 0
      this.form_otro.geslog_id = 0
      this.form_otro.correo = ''
      this.form_otro.descripcion = ''
      this.form_otro.correoenvio_id = 0
      this.form_otro.items_archivos = []
    },
    rec_gestion_update_motivo () {
      const urlroute = this.$store.getters.getUrl + 'update_motivo'
      const data = { motivo: this.itemsLectura.objMotivoQR.id, submotivo: this.itemsLectura.objSubMotivoQR.id, id: this.registro.id }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.esperar = false
        this.updated = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    eliminarMarcacionJS (pId) {
      this.borrarMarcacion = false
      this.loandingQR = true
      const urlroute = this.$store.getters.getUrl + 'eliminarMarcacion/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.rec_gestion_por_id(pId)
        this.esperar = false
      })
    }

  }
}
